import React from 'react'

export default function BlankScreen () {
  return (
    <div
        style={{
            height: "100vh",
            width: "100vw",
            background: "black"
        }}
    >
    </div>
  )
}