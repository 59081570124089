//Module Imports
import { Suspense , lazy } from "react";
import { Routes, Route } from "react-router-dom";
//Page Component Imports
import BlankScreen from "./components/BlankScreen/BlankScreen.jsx";
//Component Imports
import NavBar from "./components/NavBar.jsx";
import BottomBar from "./components/BottomBar.jsx";
//CSS Import
import './App.css';

const About = lazy(() => import('./screens/About.jsx'))
const Portfolio = lazy(() => import('./screens/Portfolio.jsx'))
const Contact = lazy(() => import('./screens/Contact.jsx'))

function App() {
  return (
    <>
      <Suspense fallback={<BlankScreen />}>
        <h1>Ryan Ehrlich Software Engineer Innovator Insurance Multinational Project Management</h1>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <BottomBar />
        </div>
      </Suspense>
    </>
  );
}

export default App;
